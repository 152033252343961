import { DEMO_POSTS } from "data/posts";
import { DashboardEmailType, PostDataType } from "data/types";
import React, { FC } from "react";
import Card3Small from "./../BlogPage/Card3Small";
import WidgetHeading1 from "./../BlogPage/WidgetHeading1";

export interface WidgetPostsProps {
  className?: string;
  emails?: DashboardEmailType[];
}

const WidgetEmails: FC<WidgetPostsProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  emails = []
}) => {

  return (
    <div
      className={`nc-WidgetPosts rounded-3xl overflow-hidden ${className}`}
      data-nc-id="WidgetPosts"
    >
      <WidgetHeading1
        title={"Recent Emails (" + emails.length + ")"}
        viewAll={{ label: "View all", href: "/#" }}
      />
      <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
        {emails.filter(
  (_, i) => i >= 0 && i < 5
).map((mailItm) => (
            <div
            className={`nc-Card3Small relative flex flex-col-reverse sm:flex-row sm:justify-between sm:items-center p-4 xl:px-5 xl:py-6 hover:bg-neutral-200 dark:hover:bg-neutral-700`}
          >
      <div className="relative space-y-2">
        <h2 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
          {mailItm.status} <br/> Sent to {mailItm.to}
        </h2>
        </div>
              
        </div>
        ))}
      </div>
    </div>
  );
};

export default WidgetEmails;
