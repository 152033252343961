import StartRating from "components/StartRating/StartRating";
import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import { auth } from "firebaseSetup"
import { signOut } from "firebase/auth"



export interface PayPageProps {
  className?: string;
}

const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  const [paymentAmountt, setPaymentAmountt] = useState<number>(0)
  useEffect(()=>{
    const guestMode = localStorage.getItem('guest') || ""
    const paymentAmount = localStorage.getItem('payment_amount') || ""
    if (paymentAmount != ""){
      const numberAmount = Number(paymentAmount)
      setPaymentAmountt(numberAmount)
      localStorage.removeItem("payment_amount")
    }
    if (auth.currentUser != null){
      if (auth.currentUser.isAnonymous){
        signOut(auth)
        localStorage.removeItem("user")
        localStorage.removeItem("user_uid")
        localStorage.removeItem("user_profile")
        localStorage.removeItem('guest')
      }
    }
    // if (guestMode == "yes" && auth.currentUser != null){
    //   //signout
    //   signOut(auth)
    //   localStorage.removeItem("user")
    //   localStorage.removeItem("user_uid")
    //   localStorage.removeItem("user_profile")
    //   localStorage.removeItem('guest')
    // }
    // else{
    //   localStorage.removeItem('guest')
    //   localStorage.removeItem("payment_amount")
    //   localStorage.removeItem("user_uid")
    // }
    
  },[])
  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Thank you for your donation!
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-xl font-semibold">Donation details have been sent to your email, please check your inbox</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Donation Amount</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {paymentAmountt.toLocaleString('en-US',{style: 'currency', currency: 'CAD'})}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Date</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {(new Date()).toLocaleDateString('en')}
              </span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Payment method</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                Credit card
              </span>
            </div>
          </div>
        </div>
        <div>
          <ButtonPrimary href="/donate">Donate more</ButtonPrimary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default PayPage;
