import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useState, useCallback, useEffect } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import { GuestsObject } from "components/HeroSearchForm/type";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import ModalSignup from "components/ModalSignup";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Grid from '@mui/material/Grid2';
import { Box, Paper, Button, IconButton, Typography, useTheme } from "@mui/material";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import Select from "shared/Select/Select";
import FormItem from "./FormItem";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from "@mui/material";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import Alert from '@mui/material/Alert';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {PaymentElement} from '@stripe/react-stripe-js';
import { fs, auth } from "firebaseSetup"
import { collection, doc, addDoc } from "firebase/firestore"
import { createGuestAccount, createNewPageView } from "hooks/EventGoAPI";
import { signInAnonymously, signOut } from 'firebase/auth'
import Terms from "components/Terms";

export interface CheckOutPagePageMainProps {
  className?: string;
}

const PageDonate: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {

  const [clientSecret, setClientSecret] = useState('')
  const [options, setOptions] = useState({clientSecret: ''});
  const [donationOnce, setDonationOnce] = useState(true)
  const [donationMonthlyDialog, setDonationMonthlyDialog] = useState(false)

  const [amountSelected, setAmountSelected] = useState(100)
  const [amountCustom, setAmountCustom] = useState(false)
  const [termsAgree, setTermsAgree] = useState(false)
  const [donationAmount, setDonationAmount] = useState(0)

  const [priceIdLoaded, setPriceIdLoaded] = useState('')
  const [showTerms, setShowTerms] = useState(false)

  const [error, setError] = useState('')
  const [showCustom, setShowCustom] = useState(false)
  
  function loadStripeSubscriptionWithUserToken(donationAmount: number, unitAmount: string, userToken: string){
    if (userToken != ""){
      fetch(`https://us-central1-cniw-foundation.cloudfunctions.net/stripeCreateSubscriptionSession?unit_amount=${unitAmount}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization": "Bearer " + userToken
        },
      }).then((response) => response.json()).then((data) => {
        console.log("reloaded")
        const clientS = data.clientSecret
        setClientSecret(clientS)
        console.log("Clients: " + clientS)
        setOptions({clientSecret: clientS})
        setDonationAmount(donationAmount)
      })
    }
    else{
      alert("No token found")
    }
  }

  function loadStripeCheckoutWithPriceId(priceId:string, userToken: string){
    if (priceId != priceIdLoaded && userToken != ""){
      fetch("https://us-central1-cniw-foundation.cloudfunctions.net/stripeCreateCheckoutSession?price_id="+priceId, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization": "Bearer " + userToken
        },
      }).then((response) => response.json()).then((data) => {
        console.log("reloaded")
        const clientS = data.clientSecret
        setClientSecret(clientS)
        setOptions({clientSecret: clientS})
        setPriceIdLoaded(priceId)
      }).catch((error) => {})
    }
    else{

    }
    
  }

  function loadStripeCheckoutWithCustomAmount(unitAmount:number, userToken: string){
    if (unitAmount >= 10 && userToken != ""){
      let unit_amount = unitAmount * 100
      fetch("https://us-central1-cniw-foundation.cloudfunctions.net/stripeCreateCustomOneTimeCheckoutSession?unit_amount="+unit_amount, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization": "Bearer " + userToken
        },
      }).then((response) => response.json()).then((data) => {
        const clientS = data.clientSecret
        setClientSecret(clientS)
        setOptions({clientSecret: clientS})
      })
    }
    else{
      setError("You have to choose at least 10 dollar")
      alert('You have to choose at least 10 dollar')
    }
  }

  useEffect(()=>{
    createNewPageView()
    loadDonationDetails()
  },[])

  const loadDonationDetails = () => {
    let donationD = localStorage.getItem("donation_redirect") || "{}"
    if (JSON.parse(donationD) != null){
      let donationFetched = {amount: 0, donation: 0, terms: true}
      donationFetched = JSON.parse(donationD)
      if (donationFetched.amount > 0){
        setAmountSelected(donationFetched.amount)
        setDonationOnce(false)
      }
      // setTermsAgree(donationFetched.terms)
      // //setAmountSelected(donationFetched.amount)
      localStorage.removeItem("donation_redirect")
    }
  }

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");
  
  const CheckoutForm = () => {
    return (
      <form>
        <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
      </form>
    );
  };



  const StripeCheckout = () => {
    return <CheckoutForm />
  }


  async function donationOnlyOnce(){

  }

  async function donationSubscription(){

  }

  const renderDonationAmount = () => {
    return (
          <div>
            <h3 className="text-2xl font-semibold">Select Donation Amounts</h3>

            <div className="space-y-1 mt-5">
            <div style={{marginTop: '25px', marginBottom: '45px'}}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {donationOnce && <ButtonPrimary onClick={()=>setDonationOnce(true)}>Donate Once</ButtonPrimary>}
              {!donationOnce && <ButtonSecondary onClick={()=>setDonationOnce(true)}>Donate Once</ButtonSecondary>}
              {!donationOnce && <ButtonPrimary onClick={()=>{
                if (auth.currentUser == null){
                  //go to signup
                  setDonationMonthlyDialog(true)
                  setDonationOnce(false)
                }
                else{
                  setDonationOnce(false)
                }
              }}>Donate Monthly</ButtonPrimary>}
              {donationOnce && <ButtonSecondary onClick={()=>{
                if (auth.currentUser == null){
                  //go to signup
                  setDonationMonthlyDialog(true)
                  setDonationOnce(false)
                }
                else{
                  setDonationOnce(false)
                }
              }}>Donate Monthly</ButtonSecondary>}
            </Grid>
            <Dialog
        open={donationMonthlyDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Donation Monthly"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Monthly Donation required to have account first
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{
            setDonationOnce(true)
            setDonationMonthlyDialog(false)
          }}>Donate Once</Button>
          <Button onClick={()=>{
            setDonationOnce(false)
            setDonationMonthlyDialog(false)
            //setup donation
            localStorage.setItem("donation_redirect",JSON.stringify({amount: amountSelected, donation: donationAmount, terms: termsAgree}))
            window.location.assign('/signup')
          }} autoFocus>Signup</Button>
        </DialogActions>
      </Dialog>
            {error && <Alert severity="error">{error}</Alert>}
            <br/>
            {(!donationOnce && auth.currentUser == null) && <Alert severity="info">{`Monthly donation required to have account!`}</Alert>}
            </div>
            
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <ButtonSecondary onClick={()=>{
                      setAmountSelected(25)
                      setShowCustom(false)
                    }}>$25</ButtonSecondary>
                    <ButtonSecondary onClick={()=>{
                      setAmountSelected(50)
                      setShowCustom(false)
                    }}>$50</ButtonSecondary>
                    <ButtonSecondary onClick={()=>{
                      setAmountSelected(75)
                      setShowCustom(false)
                    }}>$75</ButtonSecondary>
                    <ButtonSecondary onClick={()=>{
                      setAmountSelected(100)
                      setShowCustom(false)
                    }}>$100</ButtonSecondary>
                    <ButtonSecondary onClick={()=>{
                      setAmountSelected(200)
                      setShowCustom(false)
                    }}>$200</ButtonSecondary>
                    <ButtonSecondary onClick={()=>{
                      setAmountSelected(500)
                      setShowCustom(false)
                    }}>$500</ButtonSecondary>
                    <ButtonSecondary onClick={()=>{
                      setAmountSelected(1000)
                      setShowCustom(false)
                    }}>$1000</ButtonSecondary>
                    <ButtonSecondary onClick={()=>{
                      setShowCustom(true)
                    }}>Custom</ButtonSecondary>
                </Grid>

              { showCustom &&
                <div className="space-y-2" style={{marginTop: 15}}>
              <Label>Custom Amount{`  (CAD)`}</Label>
                <Input 
                  style={{
                    marginTop: '5px', 
                    marginBottom: '15px', 
                    height: '60px'
                  }}
                  type="number"  
                  placeholder="CAD"
                  min={10}
                  defaultValue={amountSelected}
                  onChange={(e)=>{
                    const amountA = e.target.value
                    const amountNum = Number(amountA) || 10
                    if (amountNum >= 10){
                      setAmountSelected(amountNum)
                    }
                    else{

                    }
                  }} />
            </div>
            }

            </div>
            

            <div className="space-y-1" style={{marginTop: '45px'}}>
              <h2 className="text-2xl font-semibold" hidden={amountSelected == 0}>Donate {amountSelected.toLocaleString('en-US',{style: 'currency', currency: 'CAD'})} {donationOnce ? ' Once' : 'Monthly'}</h2>
              <br/><br/>
              {showTerms && Terms()}
              <br/>
              <Checkbox defaultChecked={termsAgree} onChange={(e)=>{
                const checked = e.target.checked
                setTermsAgree(checked)
              }}/><Label>You are agree to our <a style={{textDecoration: 'underline'}} onClick={(e)=>{
                //expand terms
                if (showTerms){
                  setShowTerms(false)
                }
                else{
                  setShowTerms(true)
                }
                
              }}>Terms of Use</a></Label>
              <br/>
              <ButtonPrimary 
                disabled={amountSelected < 10 || !termsAgree}
                onClick={()=>{
                  if (amountSelected >= 10 && termsAgree && donationOnce){
                    setDonationAmount(amountSelected)
                    if (auth.currentUser == null){
                      signInAnonymously(auth).then((userVal)=>{
                        let userUID = userVal.user.uid
                        localStorage.setItem("user_uid",userUID)
                        localStorage.setItem('guest','yes')
                        localStorage.setItem('payment_amount',`${amountSelected}`)

                        userVal.user.getIdToken(true).then((idToken) => {
                          if (amountSelected == 25){
                            loadStripeCheckoutWithPriceId("price_1QKiX2J0CXnIpT131ixOeOc7",idToken)
                          }
                          else if (amountSelected == 50){
                            loadStripeCheckoutWithPriceId("price_1QKiZCJ0CXnIpT13Db50gnte",idToken)
                          }
                          else if (amountSelected == 75){
                            loadStripeCheckoutWithPriceId("price_1QKiZPJ0CXnIpT13esoKjpzz",idToken)
                          }
                          else if (amountSelected == 100){
                            loadStripeCheckoutWithPriceId("price_1QKiZaJ0CXnIpT13IxCHwx1G",idToken)
                          }
                          else if (amountSelected == 200){
                            loadStripeCheckoutWithPriceId("price_1QKiZmJ0CXnIpT13R407mJ6T",idToken)
                          }
                          else if (amountSelected == 500){
                            loadStripeCheckoutWithPriceId("price_1QKiZvJ0CXnIpT135g78Yigr",idToken)
                          }
                          else if (amountSelected == 1000){
                            loadStripeCheckoutWithPriceId("price_1QKia2J0CXnIpT137nwjYmVG",idToken)
                          }
                          else{
                            loadStripeCheckoutWithCustomAmount(amountSelected,idToken)
                          }

                          
                      })
                        
                      })
                    }
                    else{
                      auth.currentUser.getIdToken(true).then((idToken) => {
                        localStorage.setItem('payment_amount',`${amountSelected}`)
                        if (amountSelected == 25){
                          loadStripeCheckoutWithPriceId("price_1QKiX2J0CXnIpT131ixOeOc7",idToken)
                        }
                        else if (amountSelected == 50){
                          loadStripeCheckoutWithPriceId("price_1QKiZCJ0CXnIpT13Db50gnte",idToken)
                        }
                        else if (amountSelected == 75){
                          loadStripeCheckoutWithPriceId("price_1QKiZPJ0CXnIpT13esoKjpzz",idToken)
                        }
                        else if (amountSelected == 100){
                          loadStripeCheckoutWithPriceId("price_1QKiZaJ0CXnIpT13IxCHwx1G",idToken)
                        }
                        else if (amountSelected == 200){
                          loadStripeCheckoutWithPriceId("price_1QKiZmJ0CXnIpT13R407mJ6T",idToken)
                        }
                        else if (amountSelected == 500){
                          loadStripeCheckoutWithPriceId("price_1QKiZvJ0CXnIpT135g78Yigr",idToken)
                        }
                        else if (amountSelected == 1000){
                          loadStripeCheckoutWithPriceId("price_1QKia2J0CXnIpT137nwjYmVG",idToken)
                        }
                        else{
                          loadStripeCheckoutWithCustomAmount(amountSelected,idToken)
                        }
                      })
                    }  
                  }
                  else{
                    if (!donationOnce && termsAgree){
                      //need to signup
                      if (auth.currentUser == null){
                        //go to signup
                        setDonationMonthlyDialog(true)
                      }
                      else{
                        if (auth.currentUser.isAnonymous){
                          //signout and signup
                          signOut(auth)
                          localStorage.removeItem("user")
                          localStorage.removeItem("user_uid")
                          localStorage.removeItem("org_id")
                          localStorage.removeItem("org_name")
                          localStorage.removeItem("user_profile")
                          setDonationMonthlyDialog(true)
                          
                        }
                        else{
                          if (amountSelected > 10){
                            let unitAmount = `${amountSelected * 100}`
                          localStorage.setItem('guest','no')
                          localStorage.setItem('payment_amount',`${amountSelected}`)
                            auth.currentUser.getIdToken(true).then((idToken) => {
                              loadStripeSubscriptionWithUserToken(amountSelected, unitAmount, idToken)
                            })
                          }
                          else{
                            //amount selected less than 10
                            setError("You should select donation amount greater than 10 dollars")
                          }
                        }
                        
                        
                      }
                    }
                    else{
                      if (amountSelected < 10){
                        setError("You should select donation amount greater than 10 dollars")
                        alert("You should select donation amount greater than 10 dollars")
                      }
                      if (!termsAgree){
                        setError("You need to agree with terms to continue")
                        alert("You need to agree with terms to continue")
                      }
                    }
                  }
                }}>Donate Now</ButtonPrimary>
            </div>
            
          </div>
    )
  }
  const renderDonationScreen = () => {
    return (
      <div className="space-y-6">
      <ButtonSecondary onClick={()=>{
        setAmountSelected(0)
        setDonationAmount(0)
        setTermsAgree(false)
        localStorage.setItem('checkout_session',"")
        localStorage.setItem('checkout_amount',"")
        localStorage.setItem('checkout_price_id',"")

      }}>{"←"}</ButtonSecondary>
      {StripeCheckout()}
      </div>
    )
  }

  const renderMainContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Donate Now
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        {donationAmount == 0 && renderDonationAmount()}
        {donationAmount > 0 && renderDonationScreen()}
      </div>
    );
  };

  
  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderMainContent()}</div>
      </main>
    </div>
  );
};

export default PageDonate;
