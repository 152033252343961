import { PostDataType } from "data/types";
import { useState, useEffect } from "react";
import { auth, fs } from "firebaseSetup"
import { signInAnonymously } from 'firebase/auth'
import { UserDonationType } from 'data/types'
import { onSnapshot, addDoc, doc, collection, getDoc, getDocs, setDoc, Timestamp } from "firebase/firestore"

const API_ROOT_URL = "https://us-central1-cniw-foundation.cloudfunctions.net/"

export async function createGuestAccount(priceId: string, checkout_amount:number){
    if (auth.currentUser == null){
        //create account
        signInAnonymously(auth).then((userVal)=>{
            let userUID = userVal.user.uid
            localStorage.setItem("user_uid",userUID)
        })
    }
    else{
        let userUID = auth.currentUser.uid
        
    }
}

export async function createNewPageView(){
  addDoc(collection(fs, 'views'),{
    ip: '',
    ipLocation: '',
    timestamp: Timestamp.fromDate(new Date()),
    url: window.location.href,
    ua: window.navigator.userAgent.toLowerCase()
  }).then((docR)=>{
    const viewId = docR.id
    //update view id
    fetch(`${API_ROOT_URL}websiteUpdateVisitor?view_id=${viewId}`,{
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
      },
    })
  })
}

export async function loadUserDonations(userUID: string){
  const querySnapshot = await getDocs(collection(fs, "users", userUID, "donations"));
  querySnapshot.docs.forEach((doc) => {
    const donationD = doc.data()
    
  })
}

export async function createCheckoutSessionId(userUID: string, priceId: string, checkout_amount: number){
    let sessions = {clientSecret: ''}
    const docRef = await addDoc(collection(fs, "customers", userUID, "checkout_sessions"), 
        {   
          mode: "payment",
          ui_mode: 'embedded',
          line_items: [
              {
                price: priceId,
                quantity: 1,
              },
            ],
            billing_address_collection: 'required',
        custom_fields: [
            {
                key: 'title',
                label: {
                  type: 'custom',
                  custom: 'Title',
                },
                optional: true,
                type: 'dropdown',
                dropdown: {
                  options: [
                    {
                      label: 'Mr.',
                      value: 'mr',
                    },
                    {
                      label: 'Mrs.',
                      value: 'mrs',
                    },
                    {
                      label: 'Miss.',
                      value: 'miss',
                    },
                  ],
                },
              },
        {
          key: 'first_name',
          label: {
            type: 'custom',
            custom: 'First Name',
          },
          type: 'text',
        },
        {
          key: 'last_name',
          label: {
            type: 'custom',
            custom: 'Last Name',
          },
          type: 'text',
        },
    ],
            success_url: "https://us-central1-cniw-foundation.cloudfunctions.net/stripeCompleteCheckout?session_id=",
            cancel_url: window.location.origin + '/',
      })

      onSnapshot(docRef, (docR) => {
        const docId = docR.id
        const docRData = docR.data() || {};
        const error = docRData.error;
        const url = docRData.url
        if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured:`)
        }
        if (url){
            if (url.includes('/pay/') && url.includes('checkout.stripe.com')){
                //
                
                let sessionId = url.split('/pay/')[1]

                sessions = {clientSecret: sessionId}
                localStorage.setItem('checkout_session',sessionId)
                localStorage.setItem('checkout_amount',`${checkout_amount}`)
                localStorage.setItem('checkout_price_id',priceId)
            
                window.location.reload()

            }
        }
        
    })

}

export async function eventGoFreeItemsCheckout(
    uid:string,
    eventId:string, 
    eventName:string, 
    calculateTax:boolean,
    userName:string,
    userFirstName:string,
    userLastName:string,
    cart_items:any[],
    eventGoCoupon:string,
    userEmail:string,
    dateSelected:string,
    orgId:string,
    applyCoupon:boolean, 
    uniqueId:string){

}

export async function eventGoPayShoppingCartPayment(
    uid:string,
    eventId:string, 
    eventName:string, 
    calculateTax:boolean,
    userName:string,
    userFirstName:string,
    userLastName:string,
    cart_items:any[],
    payment_items:any[],
    userEmail:string,
    dateSelected:string,
    orgId:string,
    applyCoupon:false,
    eventGoCoupon: string, 
    stripeCoupon: string,
    uniqueId:string){

        const docRef = await addDoc(collection(fs, "service-stripe-customers", uid, "checkout_sessions"), 
        {   mode: "payment",
            automatic_tax: calculateTax,
            eventId: eventId,
            line_items: payment_items,
            allow_promotion_codes: applyCoupon,
            success_url: `${API_ROOT_URL}v1EventsAPIIssueMultipleTickets?event_id=${eventId}&user_id=${uid}&unique_id=${uniqueId}`,
            cancel_url: window.location.origin + '/payment-cancel/'+eventId,
            metadata: {
                eventId: eventId,
                eventTitle: eventName,
                orgId: orgId,
                eventName: eventName,
                firstName: userFirstName,
                lastName: userLastName,
                userUID: uid,
                url: `${API_ROOT_URL}v1EventsAPIIssueMultipleTickets?event_id=${eventId}&user_id=${uid}&unique_id=${uniqueId}`,
                fromUA: window.navigator.userAgent.toLocaleLowerCase(),
                registerEmail: userEmail,
                registerName: userName,
                uniqueId: uniqueId,
                dateSelected: dateSelected,
                calculateTax: calculateTax,
            },
        });

        onSnapshot(docRef, (docR) => {
            const docId = docR.id
            const docRData = docR.data() || {};
            const error = docRData.error;
            const url = docRData.url

            if (error) {
                // Show an error to your customer and
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occured: could not purchase ticket`)
            }
            if (url) {
                setDoc(doc(fs,"users",uid,"carts",uniqueId),{
                    eventId: eventId, 
                    orgId: orgId,
                    uniqueId: uniqueId,
                    timestamp: null,
                    shoppingCart: cart_items
                }).then((sVal) => {
                    window.location.assign(url)
                })

            }
        })

        
}


function eventGoAPI(){

}

export default eventGoAPI