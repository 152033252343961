import { DEMO_POSTS } from "data/posts";
import { DashboardViewType } from "data/types";
import React, { FC } from "react";
import Card3Small from "./../BlogPage/Card3Small";
import WidgetHeading1 from "./../BlogPage/WidgetHeading1";
import { Link } from "react-router-dom";

export interface WidgetPostsProps {
  className?: string;
  views?: DashboardViewType[];
}

const WidgetViews: FC<WidgetPostsProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  views = [],
}) => {
  return (
    <div
      className={`nc-WidgetPosts rounded-3xl overflow-hidden ${className}`}
      data-nc-id="WidgetPosts"
    >
      <WidgetHeading1
        title={`Website Visitors (${views.length})`}
        viewAll={{ label: "View all", href: "/" }}
      />
      <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
        {views.filter(
  (_, i) => i >= 0 && i < 5
).map((viewItm) => (
            <div
            className={`nc-Card3Small relative flex flex-col-reverse sm:flex-row sm:justify-between sm:items-center p-4 xl:px-5 xl:py-6 hover:bg-neutral-200 dark:hover:bg-neutral-700`}
          >
              <Link to={viewItm.url} className=" absolute inset-0" title={viewItm.timestamp.toDate().toLocaleDateString('en')}></Link>
      <div className="relative space-y-2">
        <h2 className="nc-card-title block text-base font-normal text-neutral-900 dark:text-neutral-100">
          <b>{viewItm.ipLocation}</b><br/>{viewItm.url}<br/>{viewItm.timestamp.toDate().toLocaleString('en')}
        </h2>
        </div>
        
              
        </div>
        ))}
      </div>
    </div>
  );
};

export default WidgetViews;
