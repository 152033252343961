import React, { FC, useEffect, useState } from "react";
import Heading from "components/Heading/Heading";
import { DEMO_POSTS } from "data/posts";
import { DashboardDisplayItemType, DashboardDonationType, DashboardEmailType, DashboardViewType, DonerItemType, PostDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import WidgetTags from "./../BlogPage/WidgetTags";
import WidgetCategories from "./../BlogPage/WidgetCategories";
import WidgetPosts from "./../BlogPage/WidgetPosts";
import Card3 from "./../BlogPage/Card3";
import WidgetViews from "./WidgetViews";
import WidgetEmails from "./WidgetEmails";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import HeaderFilter from "containers/PageHome/HeaderFilter";
import DashboardItemCard from "components/DashboardCard/DashboardItemCard";
import { CSVLink, CSVDownload } from "react-csv";

import ButtonSecondary from "shared/Button/ButtonSecondary";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Timestamp } from "firebase/firestore";
import Textarea from "shared/Textarea/Textarea";
import WidgetDonations from "./WidgetDonations";

// THIS IS DEMO FOR MAIN DEMO
// OTHER DEMO WILL PASS PROPS
const postsDemo: PostDataType[] = DEMO_POSTS.filter((_, i) => i > 7 && i < 14);
//
export interface SectionDashboardProps {
  donation?: DashboardDonationType[];
  views?: DashboardViewType[];
  emails?: DashboardEmailType[];
  dashboardItems: DashboardDisplayItemType[];
  allDoners?: DonerItemType[]
  className?: string;
  postCardName?: "card3";
}

const SectionDashboardControl: FC<SectionDashboardProps> = ({
  donation = [],
  views = [],
  emails = [],
  dashboardItems = [],
  allDoners = [],
  className = "",
}) => {

  const renderCard = (evt: DashboardDisplayItemType) => {
    return <DashboardItemCard data={evt} />;
  };

  const [donationDrawerOpen, setDonationDrawerOpen] = useState(false)
  const [currentDonation, setCurrentDonation] = useState<DashboardDonationType>({
  id: "",
  uid: "",
  address: "",
  amount: 0,
  currency: '',
  email: "",
  name: "",
  phone: "",
  invoice: false,
  created: 0,
  invoiceNum: "",
  type: "",
  invoiceTimestamp: Timestamp.fromDate(new Date()),
  timestamp: Timestamp.fromDate(new Date()),
  date: "",
  })
  const [currentDoner, setCurrentDoner] = useState<DonerItemType>({
    name: "",
    email: "",
    donationAmount: 0,
    donationCount: 0
  })


  const DonationDrawerList = (
    <Box 
      role="presentation" 
      style={{width: '50vw'}}
      >
        <div className="relative space-y-8" style={{margin: '16px'}}>
        <ButtonSecondary className="ml-3" onClick={()=>{
          setDonationDrawerOpen(false)
        }}>Back</ButtonSecondary>
      </div>

        <div className="relative space-y-2" style={{margin: '16px'}}>
          <div className="text-2xl font-bold">Donation Details</div>
          <div>Name: <b>{currentDonation.name}</b></div>
          <div>Address: <b><a style={{textDecoration: 'underline'}} href={`https://maps.google.com/search?q=${currentDonation.address.replace(' ', '+')}`}>{currentDonation.address}</a></b></div>
          <div>Phone Number: <b>{currentDonation.phone}</b></div>
          <div>Email Address: <b>{currentDonation.email}</b></div>
          <div>Invoice Number: <b>{currentDonation.invoiceNum}</b></div>
          <div>Donation Type: <b>{currentDonation.type == 'subscription' ? 'Monthly Donation' : 'Donate Once'}</b></div>
        </div>

        

        <div className="relative space-y-2 mt-8" style={{margin: '16px'}}>
          <div className="text-2xl font-bold">About This Doner</div>
          <div>Total Amount: <b>{currentDoner.donationAmount.toLocaleString('en-US',{style: 'currency', currency: 'CAD'})}</b></div>
          <div>Total Donation Count: <b>{currentDoner.donationCount}</b></div>
        </div>

        <div className="relative space-y-5 mt-8" style={{margin: '16px'}}>
          <div className="text-2xl font-bold">Actions</div>
          <div className="font-normal">
            <div>{currentDonation.type == 'subscription' ? 'Monthly Donation' : ''}</div>
            <div style={{color: 'red', fontWeight: 'bold'}}
            onClick={()=>{
              const confirmCancel = window.confirm("Do you want to cancel this monthly donation?\nSubscription: " + currentDonation.id)
              if (confirmCancel) {
                const donationId = currentDonation.id
                if (donationId.startsWith('sub_')){
                  //go to stripe subscription
                }
              }
            }}>{currentDonation.type == 'subscription' ? 'Cancel' : ''}</div>
          </div>
          <Textarea rows={3}/>
          <ButtonPrimary>Add Comments</ButtonPrimary>
        </div>

        

      </Box>
  )


    const donationColumn = [
        { field: "id",  headerName: "Donation ID" },
        { field: "name", headerName: "Doner Name" },
        { field: "email",  headerName: "Email" },
        { field: "address", headerName: "Address" },
        { field: "phone", headerName: "Phone Number" },
        { field: "date", headerName: "Donation Date" },
        { field: "amount", headerName: "Payment Amount" },
        { field: "comment", headerName: "Comment" },
        { field: "type", headerName: "Type" },
        { field: "invoiceNum", headerName: "Invoice Num" },
        { field: "invoice", headerName: "Receipt Received" },
        { field: 'action', headerName: 'Actions', renderCell: (params: GridCellParams) => {
          const donationId = params.id
          var donationEmail = params.row.email
          
          return <div>
            <div onClick={()=>{
              if (donationId != ""){
                const currentOne = donation.filter((donationItm) => donationItm.id === donationId)[0]
                const currentD = allDoners.filter((donerItm) => donerItm.email === donationEmail)[0]
                setCurrentDoner(currentD)
                setCurrentDonation(currentOne)
              }
              setDonationDrawerOpen(true)
            }} style={{color: 'blue'}}>{`Details`}</div>
            </div>;
        } }
      ]
  return (
    <div className={`nc-SectionLatestPosts relative ${className}`}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-5/6 xl:w-3/4 xl:pr-14">
        
          <div>
            <div className="mt-2 mb-5">
          <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2`}
      >
        {dashboardItems.map((dashItm) => renderCard(dashItm))}
      </div>
      </div>
      
          <DataGrid 
        checkboxSelection 
        sx={{ overflowX: 'scroll' }}
        rows={donation} 
        columns={donationColumn} />
         <Drawer open={donationDrawerOpen} onClose={()=>{
           setDonationDrawerOpen(false)
         }}>
              {DonationDrawerList}
          </Drawer>
          </div>
        </div>
        <div className="w-full space-y-7 mt-24 lg:mt-0 lg:w-1/6 lg:pl-10 xl:pl-0 xl:w-1/4 ">
          <WidgetDonations doners={allDoners} />
          <WidgetViews views={views}/>
          <WidgetEmails emails={emails}/>
        </div>
      </div>
    </div>
  );
};

export default SectionDashboardControl;
