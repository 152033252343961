import React, { FC, useState, useEffect } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import { auth, fs } from 'firebaseSetup'
import Alert from '@mui/material/Alert';
import PhoneInput from 'react-phone-number-input'
import { Checkbox } from "@mui/material";
import 'react-phone-number-input/style.css'
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { getDoc, getDocs, collection, doc, addDoc, setDoc, Timestamp } from "firebase/firestore"
import Terms from "components/Terms";

export interface PageSignUpProps {
  className?: string;
}



const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {

  const [showTerms, setShowTerms] = useState(false)
  const [termsAgree, setTermsAgree] = useState(false)

  const [userInfo, setUserInfo] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    phone: '',
  })

  const [error, setError] = useState("")

  async function signupCred(
    email:string, 
    password:string, 
    firstName:string, 
    lastName:string, 
    address1:string, 
    address2:string, 
    phone:string){

    createUserWithEmailAndPassword(auth,email,password).then((userVal) => {
      const userUID = userVal.user.uid
      localStorage.setItem("user_uid",userUID)
      auth.currentUser?.getIdToken(true).then((idToken) => {
        localStorage.setItem("user_token",idToken)
        setDoc(doc(fs,'users',userUID),{
          firstName: firstName,
          lastName: lastName,
          email: email,
          address1: address1,
          address2: address2,
          phone:phone,
          timestamp: Timestamp.fromDate(new Date()),
        }).then(()=>{
          window.location.assign('/donate')
        })
      })
    }).catch((e) => {
      alert('User account already exist, please login')
    })
  }

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Signup
        </h2>
        
        <div className="max-w-lg mx-auto space-y-6 ">
        {error && <Alert severity="error">{error}</Alert>}
          <form className="grid grid-cols-1 gap-6">
          <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                First Name *
              </span>
              <Input
                type="text"
                placeholder="First Name"
                className="mt-1"
                onChange={(e)=>{
                  setUserInfo({...userInfo, firstName: e.target.value})
                }}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Last Name *
              </span>
              <Input
                type="text"
                placeholder="Last Name"
                className="mt-1"
                onChange={(e)=>{
                  setUserInfo({...userInfo, lastName: e.target.value})
                }}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address *
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                onChange={(e)=>{
                  setUserInfo({...userInfo, email: e.target.value})
                }}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Phone Number *
              </span>
              <PhoneInput
                
                    value={""}
                                  defaultCountry="CA"
                                  onChange={(e) => {
                                    
                                    const phoneN = e || ""
                                    setUserInfo({...userInfo, phone: phoneN})
                                    
                                  }}/>
              
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password *
              </span>
              <Input 
                type="password" 
                className="mt-1" 
                onChange={(e)=>{
                setUserInfo({...userInfo, password: e.target.value})
              }} />
            </label>
          </form>
          {showTerms && Terms()}
          <div>
          <Checkbox defaultChecked={termsAgree} onChange={(e)=>{
                const checked = e.target.checked
                setTermsAgree(checked)
              }}/><Label>You are agree to our <a style={{textDecoration: 'underline'}} onClick={(e)=>{
                //expand terms
                if (showTerms){
                  setShowTerms(false)
                }
                else{
                  setShowTerms(true)
                }
              }}>Terms of Use</a></Label>
          </div>
          <div className="flex align-center justify-center">
            <ButtonPrimary
            className=""
            onClick={()=>{
              if (userInfo.email != "" && userInfo.password != "" && userInfo.firstName != "" && userInfo.lastName != "" && userInfo.phone != "" && termsAgree){
                signupCred(userInfo.email,userInfo.password,userInfo.firstName,userInfo.lastName,userInfo.address1,userInfo.address2,userInfo.phone)
              }
              else{
                //
                if (!termsAgree){
                  setError("You have to agree to our terms in order to signup")
                }
                else{
                  alert('Please fill out all information')
                }
              }
            }}>Signup</ButtonPrimary>
          </div>
          

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
