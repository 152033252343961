import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter, Routes, Route, useNavigate, Navigate, Outlet  } from "react-router-dom";
import { AuthContext } from "contexts/AuthContext";
import { Page } from "./types";
import { isMobile } from 'react-device-detect'
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import PayPage from "containers/PayPage/PayPage";
import AccountPage from "containers/AccountPage/AccountPage";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";

import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";
import OrganizerDashboard from "containers/AccountOrganizerPage/OrganizerDashboard";

import AccountLogout from "containers/AccountPage/AccountLogout";

import PageDonate from "containers/PageDonate/PageDonate";
import AccountDonation from "containers/AccountPage/AccountDonation";
import PageForgotPass from "containers/PageLogin/PageForgotPass";


export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/donate", exact: true, component: PageDonate },
  //
  { path: "/donation-confirmation", component: PayPage},
  { path: "/checkout", component: PageDonate },
  { path: "/donation-success", component: PayPage },
  { path: "/order-confirmation/:id", component: PayPage },
  //
  { path: "/account", protecteed: true, component: AccountPage },
  { path: "/account-donation", protecteed: true, component: AccountDonation },
  { path: "/account-logout", protecteed: true, component: AccountLogout},
  //
  //
  //
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin},
  { path: "/forgot-pass", component: PageForgotPass},

  { path: "/dashboard", protecteed: true, component: OrganizerDashboard },
  
];

const MyRoutes = () => {
  // let WIN_WIDTH = useWindowSize().width;
  // if (typeof window !== "undefined") {
  //   WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  // }

  const { currentUser } = useContext(AuthContext)

  return (
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />
      <Routes>
      {pages.map(({ component, path, protecteed }) => {
          const Component = component;
          if (protecteed != null && protecteed && !currentUser){
            return <Route key={path} element={<PageLogin />} path={path} />;
          }
          else{
            return <Route key={path} element={<Component />} path={path} />;
          }
          
        })}
        <Route element={<Page404 />} />
      </Routes>

      {isMobile && <FooterNav />}
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
