import { Tab } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StayCard from "components/StayCard/StayCard";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import React, { Fragment, useEffect, useState } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import {getDocs, getDoc, collection, query, orderBy } from "firebase/firestore"
import {auth, fs} from "firebaseSetup"
import { UserDonationType } from "data/types";
import { signOut } from "@firebase/auth";

const AccountDonation = () => {
  let [categories] = useState(["Donation","Subscription"]);
  const [donations,setDonations] = useState<UserDonationType[]>([])
  const [subscriptions, setSubscriptions] = useState<UserDonationType[]>([])

  const donationColumn = [
    { field: "id", flex: 1, headerName: "Donation ID" },
    { field: "amount", flex: 1, headerName: "Amount" },
    { field: "date", flex: 1, headerName: "Donation Date" },
  ]
  const subscriptionColumn = [
    { field: "id", headerName: "ID" },
    { field: "amount", headerName: "Amount" },
    { field: "date", headerName: "Date" },
    { field: "type", headerName: "Monthly" },
    { field: "status", headerName: "Status" },
    { field: "action", flex: 1, headerName: "Actions", renderCell: (params: GridCellParams) => {
      return <div onClick={()=>{
        var donationSubId = params.row.id as string
        let cancelConfirm = window.confirm("Do you want to cancel monthly donation? ")
        if (cancelConfirm){
          //cancel
          sendCancelDonationRequest(donationSubId)
        }
      }}>
        <span style={{color: 'red'}}>Cancel Donation</span>
      </div>
    }}
  ]

  useEffect(()=>{
    checkAccountInfo()
    loadUserDonations()
    loadUserSubscriptions()
  },[])

  function sendCancelDonationRequest(donationId: string){
    if (donationId != "" && donationId != null){
      if (auth.currentUser != null){
        auth.currentUser.getIdToken(true).then((userToken) => {
          fetch("https://us-central1-cniw-foundation.cloudfunctions.net/cancelMonthlyDonationRequest?id="+donationId, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + userToken
            }
          })
          //
          window.alert("Donation cancellation request has been sent")
        })
      }
    }
  }

  function checkAccountInfo(){
    if (auth.currentUser != null){
      if (auth.currentUser.isAnonymous) {
        signOut(auth)
        localStorage.removeItem("user")
        localStorage.removeItem("user_uid")
        localStorage.removeItem("user_profile")
        localStorage.removeItem('guest')
        window.location.assign('/')
      }
    }
  }

  async function loadUserSubscriptions(){
    if (auth.currentUser != null){
      const userUID = auth.currentUser.uid
      const querySnapshot = await getDocs(collection(fs, "users", userUID, "subscriptions"));
      let uDonations: UserDonationType[] = []
      querySnapshot.docs.forEach((doc) => {
        const donationD = doc.data()
        const donationId = doc.id
        let dAmount = 0
        let dDate = ""
        let unixTime = 0
        let dStatus = ""
        if (donationD.amount != null){
          dAmount = donationD.amount
        }
        if (donationD.created != null){
          unixTime = donationD.created
          var date = new Date(unixTime * 1000);
          dDate = date.toLocaleDateString('en')
        }
        if (donationD.status != null){
          dStatus = donationD.status
        }
        uDonations.push({
          id: donationId,
          amount: dAmount,
          date: dDate,
          status: dStatus,
        })
      })
      setSubscriptions(uDonations)
      
    }
  }

  async function loadUserDonations(){
    if (auth.currentUser != null){
      const userUID = auth.currentUser.uid
      const querySnapshot = await getDocs(collection(fs, "users", userUID, "donations"));
      let uDonations: UserDonationType[] = []
      querySnapshot.docs.forEach((doc) => {
        const donationD = doc.data()
        const donationId = doc.id
        let dAmount = 0
        let dDate = ""
        let unixTime = 0
        if (donationD.amount != null){
          dAmount = donationD.amount
        }
        if (donationD.created != null){
          unixTime = donationD.created
          var date = new Date(unixTime * 1000);
          dDate = date.toLocaleDateString('en')
        }
        uDonations.push({
          id: donationId,
          amount: dAmount,
          date: dDate,
          status: '',
        })
      })
      setDonations(uDonations)
    }
    
  }


  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold">Donations</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="mt-8">
                <DataGrid 
                    sx={{ overflowX: 'scroll' }}
                    rows={donations} 
                    columns={donationColumn} />
              </Tab.Panel>
              <Tab.Panel className="mt-8">
              <DataGrid 
                    sx={{ overflowX: 'scroll' }}
                    rows={subscriptions} 
                    columns={subscriptionColumn} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountDonation;
