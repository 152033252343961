import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { auth, fs } from 'firebaseSetup'
import { signInWithEmailAndPassword } from "firebase/auth";
import { getDoc, getDocs, collection, doc, addDoc } from "firebase/firestore"

export interface PageLoginProps {
  className?: string;
}



const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const [userInfo, setUserInfo] = useState({
    email: '',
    password: '',
  })

  async function loginCred(email:string, password:string){
    signInWithEmailAndPassword(auth,email,password).then((userVal) => {
      const userUID = userVal.user.uid
      localStorage.setItem("user_uid",userUID)
      window.location.assign('/donate')
    }).catch((e)=>{
      alert("ERROR: your email or password is incorrect")
    })
  }
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>CNIW Foundation | Login to your account</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <form className="grid grid-cols-1 gap-6">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                onChange={(e)=>{
                  const emailA = e.target.value
                  let userIn = userInfo
                  userIn.email = emailA
                  setUserInfo(userIn)
                }}
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm">
                  Forgot password?
                </Link>
              </span>
              <Input type="password" onChange={(e)=>{
                const passwordA = e.target.value
                let userIn = userInfo
                userIn.password = passwordA
                setUserInfo(userIn)

              }} className="mt-1" />
            </label>
            
          </form>
          <div className="flex align-center justify-center">
          <ButtonPrimary onClick={()=>{
            loginCred(userInfo.email,userInfo.password)
          }}>Login</ButtonPrimary>
          </div>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup">Create an account</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
