import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React, { useEffect, useState } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import SectionGridFeatureEvents from "./SectionGridFeatureEvents";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType, EventDataType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import useEventDataLoader from "hooks/useEventDataLoader";
import SectionHero3 from "components/SectionHero/SectionHero3";
import Heading2 from "components/Heading/Heading2";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/credit-card-logo.png";
import { createNewPageView } from "hooks/EventGoAPI";

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "New Yourk",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/64271/queen-of-liberty-statue-of-liberty-new-york-liberty-statue-64271.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "Singapore",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7740160/pexels-photo-7740160.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "Paris",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/739407/pexels-photo-739407.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "London",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "Tokyo",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/4151484/pexels-photo-4151484.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "2",
    href: "/listing-stay",
    name: "Maldives",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/3250613/pexels-photo-3250613.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

const DEMO_CATS_2: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Enjoy the great cold",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "222",
    href: "/listing-stay",
    name: "Sleep in a floating way",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/2869499/pexels-photo-2869499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Cool in the deep forest",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/247532/pexels-photo-247532.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

function PageHome() {

  useEffect(()=>{
    createNewPageView()
  },[])
  
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      {/* <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <SectionHero3 className="" />
      </div> */}

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION HERO */}
        
        <SectionHero3 className="" />
        {/* <SectionHero className="pt-10 lg:pt-16 lg:pb-16" /> */}
        <SectionVideos />
        
        <div
        id=""
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <p className="text-2xl font-semibold">Why Your Support Matters: Building a Strong Cancer Support Network Together</p>
        <p>
          In Canada, cancer affects thousands of individuals and their families each year, and the need for strong support systems is greater than ever. By donating to our cause, you are not only helping to fund vital services but also contributing to the creation of a compassionate, empowering community where no one faces cancer alone.
        </p>
        <p className="text-2xl font-semibold">The Growing Need for Cancer Support</p>
        <p>
        In 2023, over 240,000 Canadians were diagnosed with cancer, and nearly 1 in 2 Canadians are expected to be diagnosed with cancer in their lifetime. In Ontario alone, more than 90,000 new cases are reported each year. These numbers are more than just statistics—they represent real people and real families whose lives are forever changed by this devastating disease.
        </p>
        <p>
        Cancer doesn’t just affect the individual diagnosed; it impacts their entire family. Families often face emotional turmoil, financial stress, and feelings of isolation while navigating treatment plans, caregiving responsibilities, and the uncertainty of the future.
        </p>
        <p>
        This is why building a strong mutual cancer support group is so critical. We believe that no one should have to go through this journey alone.
        </p>
        <p>
        How Your Donation Makes a Difference
        </p>
        <p>
        Your generous donation helps us to create and maintain a support network that provides:
        </p>
        <ul>
          <li>Emotional support and counseling for individuals and families affected by cancer.</li>
          <li>Peer support programs, where survivors and those currently in treatment can connect, share experiences, and uplift each other.</li>
          <li>Resource sharing and educational workshops to help families navigate the complexities of cancer care.</li>
          <li>Caregiver support to assist those who are providing care for their loved ones during this difficult time.</li>
        </ul>
        <p>
        The Power of a Strong Support System
        </p>
        <p>
        Research shows that having a robust support network improves the emotional and psychological well-being of both patients and caregivers, often leading to better outcomes in the cancer journey. For those battling cancer, knowing they are not alone and that they have a community behind them can make all the difference. This is why your support is crucial in helping us to foster connections and build resilience within our community.
Every Dollar Counts
        </p>
        <p>
        By donating to our cause, you’re directly impacting the lives of those facing cancer in Ontario. Every dollar brings us closer to our goal of ensuring that no individual or family feels isolated in their struggle. Whether you are able to contribute a small amount or a large sum, your donation goes towards building a community of care, hope, and support for those who need it most.
        </p>
        <p className="text-2xl font-semibold">
        Join Us in Making a Difference
        </p>
        <p>
        Your contribution to our mutual cancer support group can provide hope and comfort to individuals and families affected by cancer. Together, we can build a stronger, more compassionate community where everyone has the support they need on their cancer journey.
        </p>
        <p>
        How Your Donation Will Be Used
        </p>
        <p>
        Your generous contribution will directly support our mission to empower cancer patients and their families through:
        </p>
        <ul>
          <li>Support and Care: We provide emotional, psychological, and practical support to cancer patients and their families, helping them navigate the challenges of diagnosis and treatment with compassion and understanding.</li>
          <li>Information Sharing: We offer access to vital information about treatment options, medical resources, the latest research, and tips for healthy living, helping patients and caregivers make informed decisions about their care.</li>
          <li>Advocacy and Education: We work to raise public awareness about cancer prevention and treatment, advocate for improved care conditions, and help enhance the quality of life and social standing for those affected by cancer.</li>
          <li>Research: Your donation will also contribute to cancer research, supporting efforts to find better treatments, improve survival rates, and ultimately work toward a cure.
Together, we can make a meaningful difference in the lives of those touched by cancer.</li>
        </ul>
        <p>
        Together, we can make a meaningful difference in the lives of those touched by cancer.
        </p>
        <div className="flex align-center justify-center">
          <ButtonPrimary onClick={()=>{
            window.location.assign('/donate')
          }}>Donate to Support This Work</ButtonPrimary>
        </div>
        <div>
        <img width={150} height={60} src={imagePng} />
        </div>
        
      </div>


      </div>
    </div>
  );
}

export default PageHome;
