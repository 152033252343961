import { DEMO_POSTS } from "data/posts";
import { DonerItemType, PostDataType } from "data/types";
import React, { FC, useState } from "react";
import Card3Small from "./../BlogPage/Card3Small";
import WidgetHeading1 from "./../BlogPage/WidgetHeading1";
import { Link } from "react-router-dom";

export interface WidgetPostsProps {
  className?: string;
  doners?: DonerItemType[]
}

const widgetPostsDemo: PostDataType[] = DEMO_POSTS.filter(
  (_, i) => i > 2 && i < 7
);

const WidgetDonations: FC<WidgetPostsProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  doners = []
}) => {
  
  const [showAll, setShowAll] = useState(false)

  return (
    <div
      className={`nc-WidgetPosts rounded-3xl overflow-hidden ${className}`}
      data-nc-id="WidgetPosts"
    >
      <div
      className={`nc-WidgetHeading1 flex items-center justify-between p-4 xl:p-5 border-b border-neutral-200 dark:border-neutral-700 ${className}`}
      data-nc-id="WidgetHeading1"
    >
      <h2 className="text-lg text-neutral-900 dark:text-neutral-100 font-semibold flex-grow">
        {"Top Doners"}
      </h2>
      <Link
          className="flex-shrink-0 block text-primary-700 dark:text-primary-500 font-semibold text-sm"
          rel="noopener noreferrer"
          to=""
          onClick={(e)=>{
            setShowAll(true)
          }}
        >
          View All
        </Link>
      </div>

      <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
      {doners.filter(
  (_, i) => i >= 0 && i < 5
).map((donerItm) => (
            <div
            className={`nc-Card3Small relative flex flex-col-reverse sm:flex-row sm:justify-between sm:items-center p-4 xl:px-5 xl:py-6 hover:bg-neutral-200 dark:hover:bg-neutral-700`}
          >
      <div className="relative space-y-2">
        <h2 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
          {donerItm.name} ({donerItm.donationCount} Donations)<br/>Total: {donerItm.donationAmount.toLocaleString('en-US',{style: 'currency', currency: 'CAD'})}
        </h2>
        </div>
              
        </div>
        ))}
      </div>
    </div>
  );
};

export default WidgetDonations;
