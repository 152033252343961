import Label from "components/Label/Label";
import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { auth, fs} from "firebaseSetup"
import { signOut } from "firebase/auth";

const AccountLogout = () => {
  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Are you sure to logout?</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className=" max-w-xl space-y-6">
            <div className="pt-2">
              <ButtonPrimary onClick={()=>{
                  if (auth.currentUser != null){
                    signOut(auth)
                    localStorage.removeItem("user")
                    localStorage.removeItem("user_uid")
                    localStorage.removeItem("org_id")
                    localStorage.removeItem("org_name")
                    localStorage.removeItem("user_profile")
                    window.location.href = "/"
                  }
              }}>Logout</ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountLogout;
