import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import { getDoc, getDocs, collection, doc } from "firebase/firestore"
import { auth, fs} from "firebaseSetup"
import { signOut } from "firebase/auth";
import { UserDonationType } from "data/types";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {

  const [userObj, setUserObj] = useState({
    firstName: "",
    lastName: "",
    name: "",
    email: "",
    phone: "",
    profileURL: "",
  })

  function checkAccountInfo(){
    if (auth.currentUser != null){
      if (auth.currentUser.isAnonymous) {
        signOut(auth)
        localStorage.removeItem("user")
        localStorage.removeItem("user_uid")
        localStorage.removeItem("user_profile")
        localStorage.removeItem('guest')
        window.location.assign('/')
      }
    }
  }


  async function loadUserInfo(){
    if (auth.currentUser != null && !auth.currentUser?.isAnonymous){
      const userUID = auth.currentUser?.uid || ""
      const docSnapshot = await getDoc(doc(fs,'users',userUID));
      if (docSnapshot.exists()){
        //find user info
        let userD = docSnapshot.data()
        let fn = ""
        let ln = ""
        let email = auth.currentUser?.email || ""
        let phone = ""
        if (userD.firstName != null){
          fn = userD.firstName
        }
        if (userD.lastName != null){
          ln = userD.lastName
        }
        if (userD.phone != null){
          phone = userD.phone
        }
        setUserObj({
          firstName: fn,
          lastName: ln,
          name: fn + " " + ln,
          email: email,
          phone: phone,
          profileURL: ""
        })
      }
    }
  }
  useEffect(()=>{
    checkAccountInfo()
    loadUserInfo()
  },[])

  

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event Go || My Account</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Account infomation</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          
          <div className="flex-grow mt-10 md:mt-0 md:pl-0 max-w-3xl space-y-3">
              <div>
                <Label>First Name</Label>
                <Input className="mt-1.5" defaultValue={userObj.firstName} />
              </div>
              {/* ---- */}
              <div>
                <Label>Last Name</Label>
                <Input className="mt-1.5" defaultValue={userObj.lastName} />
              </div>
              {/* ---- */}
              <div>
                <Label>Phone</Label>
                <Input className="mt-1.5" defaultValue={userObj.phone} disabled />
              </div>
              <div>
                <Label>Email</Label>
                <Input className="mt-1.5" defaultValue={auth.currentUser?.email || ""} disabled />
              </div>
              <div className="pt-2">
                <ButtonPrimary>Update info</ButtonPrimary>
              </div>
            </div>
            
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
