import Label from "./../../components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import Avatar from "./../../shared/Avatar/Avatar";
import ButtonPrimary from "./../../shared/Button/ButtonPrimary";
import Input from "./../../shared/Input/Input";
import Select from "./../../shared/Select/Select";
import Textarea from "./../../shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import Grid from '@mui/material/Grid2';
import { Box, Paper, Button, IconButton, Typography, useTheme } from "@mui/material";
import { auth,fs } from "firebaseSetup";
import { DashboardDonationType, DashboardViewType, DashboardEmailType, DashboardDisplayItemType, DonerItemType } from "data/types";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionDashboardControl from "./SectionDashboardControl";
import { onSnapshot, addDoc, doc, collection, getDoc, getDocs, setDoc, Timestamp, orderBy, query, where } from "firebase/firestore"
import HeaderFilter from "containers/PageHome/HeaderFilter";
import FormItem from "containers/PageCreateEvent/FormItem"


export interface DashboardProps {
  className?: string;
}

const OrganizerDashboard: FC<DashboardProps> = ({ className = "" }) => {


  const [admin,setAdmin] = useState(false)
  const [donationData, setDonationData] = useState<DashboardDonationType[]>([])
  const [viewData, setViewData] = useState<DashboardViewType[]>([])
  const [emailData, setEmailData] = useState<DashboardEmailType[]>([])
  const [allDonerDetails, setAllDonerDetails] = useState<DonerItemType[]>([])
  const [dashboards, setDashboards] = useState<DashboardDisplayItemType[]>([])

  const [customSearchView, setCustomSearchView] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState({
    minAmount: 0,
    maxAmount: 0,
    minDate: (new Date()).toLocaleDateString("en-CA"),
    maxDate: (new Date()).toLocaleDateString("en-CA"),
    keywords: ''
  })
  const [currentTab, setCurrentTab] = useState<string>('All')
  const [allFilters, setAllFilters] = useState<string[]>([
    'All',
    'Today',
    'Last 7 Days',
    'This Month',
    'This Year',
    'Search'
  ])

  const goBackHomePage = () => {
    window.location.assign('/')
  }

  const loadDonationData = (filter:string) => {
    var unixMaxTimestamp = Date.now()
    var unixMinTimestamp = Date.now()

    let todayBegin = new Date(new Date(new Date((new Date()).setHours(0)).setMinutes(0)).setSeconds(0))
    let last7Day = new Date(new Date().setDate(new Date().getDay() - 7))
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDayOfYear = new Date(y,1,1);
    var firstDayOfMonth = new Date(y, m, 1);
    var lastDayOfMonth = new Date(y, m+1, 0);

    if (filter == 'All'){
      unixMaxTimestamp = Date.now()
      unixMinTimestamp = (new Date(1970,1,1)).getTime() / 1000
    }
    else if (filter == 'Today'){
      unixMaxTimestamp = Date.now()
      unixMinTimestamp = todayBegin.getTime() / 1000
    }
    else if (filter == 'Last 7 Days'){
      unixMaxTimestamp = Date.now()
      unixMinTimestamp = last7Day.getTime() / 1000
    }
    else if (filter == 'This Month'){
      unixMaxTimestamp = Date.now()
      unixMinTimestamp = firstDayOfMonth.getTime() / 1000
    }
    else if (filter == 'This Year'){
      unixMaxTimestamp = Date.now()
      unixMinTimestamp = firstDayOfYear.getTime() / 1000
    }
    else if (filter == "Search"){
      //use search filter
      if (searchQuery.minDate != ""){
        unixMinTimestamp = (new Date(searchQuery.minDate)).getTime() / 1000
      }
      if (searchQuery.maxDate != ""){
        unixMaxTimestamp = (new Date(searchQuery.maxDate)).getTime() / 1000
      }
      //other search filter
    }
    else{
      unixMaxTimestamp = Date.now()
      unixMinTimestamp = (new Date(1970,1,1)).getTime()
    }
    const qdonate = query(collection(fs, 'donations'), where('created','>',unixMinTimestamp), where('created','<=', unixMaxTimestamp), orderBy('created','desc'));
    getDocs(qdonate).then((snapshot)=>{
      let newDonationSet: DashboardDonationType[] = []
      let dashboardData: DashboardDisplayItemType[] = []

      snapshot.docs.forEach((doc)=>{
        let donationId = doc.id
        let donationData = doc.data()
        let donationCreated = donationData.created || 0
        let donationDate = ""
        let donationType = ""
        if (donationData.type != null){
          donationType = donationData.type
        }
        else{
          donationType = "Once"
        }
        if (donationCreated > 0) {
          var date = new Date(donationCreated * 1000);
          donationDate = date.toLocaleString('en')
        }
        let newDonation: DashboardDonationType = {
          id: donationId,
          uid: donationData.uid || "",
          address: donationData.address || "",
          amount: donationData.amount || 0,
          currency: donationData.currency || "",
          email: donationData.email || "",
          name: donationData.name || "",
          phone: donationData.phone || "",
          invoice: donationData.invoice || "",
          created: donationData.created || 0,
          invoiceNum: donationData.invoiceNum || "",
          date: donationDate,
          type: donationType || "",
          invoiceTimestamp: donationData.timestamp || Timestamp.fromDate(new Date()),
          timestamp: donationData.timestamp || Timestamp.fromDate(new Date()),
        }

        if (filter == "Search"){
          //use other search filter
          var keyW = searchQuery.keywords
          var minAmount = searchQuery.minAmount
          var maxAmount = searchQuery.maxAmount
          if (searchQuery.minAmount > 0){
            minAmount = searchQuery.minAmount
          }
          if (searchQuery.maxAmount > searchQuery.minAmount) {
            maxAmount = searchQuery.maxAmount
          }
          let keyWMatch = false
          let amountMatch = false
          if (keyW != "") {
            if (newDonation.email.includes(keyW) 
              || newDonation.name.includes(keyW)
              || newDonation.phone.includes(keyW)
              || newDonation.address.includes(keyW))
              {
                keyWMatch = true
              }
          }
          if (newDonation.amount >= minAmount && newDonation.amount <= maxAmount){
            amountMatch = true
          }
          if (keyWMatch || amountMatch) {
            newDonationSet.push(newDonation)
          }
        }
        else{
          newDonationSet.push(newDonation)
        }
        
      })

      //calculate data
      let totalDonationAmount = 0
      let uniquePhoneNumber = 0
      let uniqueEmail = 0
      let uniqueAddress = 0
      let numberOfDonations = newDonationSet.length

      let emailList: string[] = []
      let phoneList: string[] = []
      let addressList: string[] = []
      //email: amount
      //
      let allDoners: DonerItemType[] = []

      let topDoner: DonerItemType = {
        name: '',
        email: '',
        donationAmount: 0,
        donationCount: 0,
      }
      
      newDonationSet.forEach((donationT) => {
        totalDonationAmount += donationT.amount

        //create current doner
        let currentDoner = allDoners.filter((donerItm) => {
          return donerItm.email == donationT.email
        })
        let donerIndex = allDoners.findIndex(x => x.email === donationT.email)
        if (currentDoner.length == 1) {
          //update
          var donerInfo = currentDoner[0]
          donerInfo.donationAmount += donationT.amount
          donerInfo.donationCount += 1
          if (donerIndex >= 0 && donerIndex < allDoners.length){
            allDoners[donerIndex] = donerInfo
          }
        }
        else if (currentDoner.length == 0) {
          allDoners.push({
            name: donationT.name,
            email: donationT.email,
            donationAmount: donationT.amount,
            donationCount: 1,
          })
        }


        
        if (!emailList.includes(donationT.email)){
          emailList.push(donationT.email)
        }
        if (!phoneList.includes(donationT.phone)){
          phoneList.push(donationT.phone)
        }
        if (!addressList.includes(donationT.address)){
          addressList.push(donationT.address)
        }
        
      })

      allDoners.sort((donerItmA, donerItmB) => {
        return donerItmB.donationAmount - donerItmA.donationAmount
      })
      if (allDoners.length > 0){
        topDoner = allDoners[0]
      }
      setAllDonerDetails(allDoners)


      uniquePhoneNumber = phoneList.length
      uniqueEmail = emailList.length
      uniqueAddress = addressList.length

      dashboardData.push({
        title: 'Total Donation Amount',
        display: totalDonationAmount.toLocaleString('en-US',{style: 'currency', currency: 'CAD'}),
        increase: 0
      })
      dashboardData.push({
        title: 'Number of Donations',
        display: `${numberOfDonations}`,
        increase: 0
      })
      dashboardData.push({
        title: 'Unique Emails',
        display: `${uniqueEmail}`,
        increase: 0
      })
      dashboardData.push({
        title: 'Unique Phone Number',
        display: `${uniquePhoneNumber}`,
        increase: 0
      })
      dashboardData.push({
        title: 'Unique Address',
        display: `${uniqueAddress}`,
        increase: 0
      })
      dashboardData.push({
        title: 'Top Doner',
        display: `${topDoner.name}: ${topDoner.donationAmount.toLocaleString('en-US',{style: 'currency',currency: 'CAD'})}`,
        increase: 0
      })

      
      
      setDonationData(newDonationSet)
      setDashboards(dashboardData)
    })
    
  }

  const loadEmailData = (filter:string) => {
    const qEmails = query(collection(fs, 'mail'), orderBy('delivery.startTime','desc'));
    getDocs(qEmails).then((snapshot) => {
      let newEmails: DashboardEmailType[] = []
      snapshot.docs.forEach((doc) => {
        const emailid = doc.id
        const emailD = doc.data()
        let emailTos = ['']
        emailTos = emailD.to
        let newEmail: DashboardEmailType = {
          id: emailid,
          from: 'CNIW Server',
          to: emailTos[0],
          status: emailD.delivery.state || '',
          timestamp: Timestamp.fromDate(new Date())
        }
        newEmails.push(newEmail)
      })
      setEmailData(newEmails)
    })
  }
  const loadViewData = (filter:string) => {
    const qviews = query(collection(fs, 'views'), orderBy('timestamp','desc'));
    getDocs(qviews).then((snapshot)=>{
      let newViewSet: DashboardViewType[] = []
      snapshot.docs.forEach((doc)=>{
        let viewId = doc.id
        let viewData = doc.data()
        let newView: DashboardViewType = {
          id: viewId,
          uid: viewData.uid || '',
          url: viewData.url || '',
          ip: viewData.ip || '',
          ipLocation: viewData.ipLocation || '',
          timestamp: viewData.timestamp || Timestamp.fromDate(new Date())
        }
        newViewSet.push(newView)
      })
      setViewData(newViewSet)

    })

  }

  useEffect(()=>{
    //check if user has proper
    if (auth.currentUser == null){
      //route to go back
      goBackHomePage()
    }
    else{
      let userUID = auth.currentUser.uid
      getDoc(doc(fs,'admin',userUID)).then((adminSnap) => {
        if (adminSnap.exists()){
          //allow access
          setAdmin(true)
          //load info
          loadDonationData(currentTab)
          loadViewData(currentTab)
          loadEmailData(currentTab)
        }
        else{
          //route to go back
          goBackHomePage()
        }
      })
    }
    
  },[])

  function reloadDataWithFilter(filter:string){
    if (admin) {
      loadDonationData(filter)
      loadViewData(filter)
      loadEmailData(filter)
    }
  }

  return (
    <div className="nc-BlogPage overflow-hidden relative">
      <Helmet>
        <title>CNIW Foundation | Admin Dashboard</title>
      </Helmet>
      <BgGlassmorphism />
      {admin && (
        <div className="container relative py-8">
          <HeaderFilter
        tabActive={currentTab}
        subHeading={currentTab + " Donations"}
        tabs={allFilters}
        heading={'Donations (' + currentTab + ')'}
        downloadHeader={[
          { label: "Doner Name", key: "name" },
          { label: "Address", key: "address" },
          { label: "Email", key: "email" },
          { label: "Phone Number", key: "phone" },
          { label: "Donation Date", key: "date" },
          { label: "Donation Method", key: "method" },
          { label: "Donation Type", key: "type" },
          { label: "Payment Amount", key: "amount" },
          { label: "Receipt Issued", key: "invoice" },
          { label: "Receipt Number", key: "invoiceNum" },
        ]}
        downloadData={donationData}
        onDownload={(item)=>{
        }}
        onClickTab={(tab) => {
          setCurrentTab(tab)
          if (tab == 'Search'){
            setCustomSearchView(true)
          }
          else{
            setCustomSearchView(false)
            reloadDataWithFilter(tab)
          }
          
        }}
      />
      { customSearchView && 
      <>
      <div className="listingSection__wrap ">
        <FormItem label="Search by keywords">
          <Input type="text" onChange={(e)=>{
            const keyW = e.target.value
            setSearchQuery({...searchQuery, keywords: keyW})
            console.log(searchQuery)
          }} defaultValue={searchQuery.keywords}/>
        </FormItem>
        <FormItem label="Search by date">
          <Input type="date" placeholder="Start Date" defaultValue={searchQuery.minDate} onChange={(e)=>{
            const keyW = e.target.value
            setSearchQuery({...searchQuery, minDate: keyW})
            console.log(searchQuery)
          }}/>
          <Input type="date" placeholder="End Date" defaultValue={searchQuery.maxDate} min={searchQuery.minDate} onChange={(e)=>{
            const keyW = e.target.value
            setSearchQuery({...searchQuery, maxDate: keyW})
            console.log(searchQuery)
          }}/>
        </FormItem>
        <FormItem label="Search by amount">
          <Input type="number" placeholder="Min Amount" defaultValue={searchQuery.minAmount} min={0} onChange={(e)=>{
            const keyW = e.target.value
            setSearchQuery({...searchQuery, minAmount: Number(keyW)})
            console.log(searchQuery)
          }}/>
          <Input type="number" placeholder="Max Amount" defaultValue={searchQuery.maxAmount} min={searchQuery.minAmount} onChange={(e)=>{
            const keyW = e.target.value
            setSearchQuery({...searchQuery, maxAmount: Number(keyW)})
            console.log(searchQuery)
          }}/>
        </FormItem>
        <div>
          <ButtonPrimary onClick={()=>{
            reloadDataWithFilter('Search')
          }}>Search</ButtonPrimary>
        </div>
      </div>
      </>}
          <SectionDashboardControl className="py-8 lg:py-8" donation={donationData} views={viewData} emails={emailData} dashboardItems={dashboards} allDoners={allDonerDetails} />
        </div>
      )}
      
    </div>
    
  );
};

export default OrganizerDashboard;
