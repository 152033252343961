import React from "react";

const Terms = () => {
  return (
    <div
    id=""
    className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
  >
    <p className="text-2xl font-semibold">Terms of Use</p>
    <p className="text-1xl font-semibold">1. General</p>
    <p>
      By using this website, you agree to use it for legitimate purposes and in compliance with all applicable laws, including privacy and intellectual property regulations. You agree to provide accurate information, such as your email address, mailing address, and other contact details, as necessary for completing transactions or communications. You authorize us to use this information as outlined in our Privacy Policy.
    </p>
    <p className="text-1xl font-semibold">2. Link to Other Websites</p>
    <p>
      This website may include links to third-party websites for your convenience. We do not control or endorse the content or practices of these sites and are not responsible for their policies. Your use of linked sites is at your own risk.
    </p>
    <p className="text-1xl font-semibold">3. Disclaimer</p>
    <p>
      We make every effort to ensure the accuracy of content and security of our website. However, we do not guarantee that the website will be free from errors, viruses, or harmful components. You assume full responsibility for your use of our website, and we are not liable for any damages resulting from your use or inability to use the site or any content provided.
    </p>
    <p className="text-1xl font-semibold">4. Privacy Policy</p>
    <p>
    Our Privacy Policy governs how we collect, use, and protect the personal information of users who engage with our website and fundraising activities. Personal information may include your name, email address, mailing address, and phone number, but excludes business information. We are committed to protecting your privacy and will not sell or disclose your personal information to non-affiliated organizations without your consent, except as required by law or to third-party service providers bound by confidentiality.
    </p>
    <p className="text-1xl font-semibold">5. Information We Collect</p>
    <p>
    We may collect personal data that can identify you, including your name, postal or email address, and phone number, as well as information provided through online forms, event registrations, or phone inquiries. We do not store credit or debit card information. All payments are securely processed by a third-party provider, and we do not have access to this financial information.
    </p>
    <p className="text-1xl font-semibold">6. Use of Information</p>
    <p>
    We may use your contact information for purposes such as:
    </p>
    <ul>
          <li>Issuing donation receipts for tax purposes.</li>
          <li>Updating you on project progress and outcomes.</li>
          <li>Informing you of new programs, events, or activities.</li>
          <li>Responding to your inquiries.</li>
    </ul>

    <p className="text-1xl font-semibold">7. Security</p>
    <p>
    We use industry-standard security measures, including SSL encryption, to protect the information you provide through our website. While we strive to safeguard your data, we cannot guarantee the security of information transmitted over the internet. Once received, we store your personal information securely, and only authorized personnel have access to it.
    </p>
    <p className="text-1xl font-semibold">8. Contact Information</p>
    <p>
    For any questions or concerns regarding our Privacy Policy or Terms of Use, you can contact us at:
Address: 80 Acadia Avenue, Markham, Ontario, L3R 9V1
Email: info@cniw.org
Phone: 1-647-451-8578
    </p>
    </div>
  );
};

export default Terms;
